<template>
  <CardToolbox>
    <sdPageHeader :title="locale.page.userList.title">
      <template #subTitle>
        <span class="title-counter">{{ count }} gebruiker{{ count > 0 && count < 2 ? '' : 's' }}</span>
        <AutoCompleteStyled style="width:100%" placeholder="Zoeken op naam..." class="mb-20">
          <a-input type="input" v-model:value="search">
            <template #suffix>
              <sdFeatherIcons type="search"/>
            </template>
          </a-input>
        </AutoCompleteStyled>
      </template>
      <template #buttons>
        <UserAdd v-if="$can('updatepermissions', 'user')"/>
      </template>
    </sdPageHeader>
  </CardToolbox>
  <Main>
    <a-row :gutter="15" class="mb-25">
      <a-col :md="24">
        <a-collapse v-model:activeKey="activeKey" :expand-icon-position="expandIconPosition">
          <a-collapse-panel key="1" header="Filters">
            <template #extra>
              <a-badge v-if="hasFilters" dot style="margin-left:0;line-height: 24px" class="mr-2">
                <sdFeatherIcons type="filter" size="16"/>
              </a-badge>
            </template>
            <a-form :model="filters" layout="vertical" @finish="applyFilters">
              <a-row :gutter="16">
                <a-col :md="8" :xs="24" class="mb-20">
                  <a-form-item label="Organisatie" name="organizationID" style="margin-bottom: 5px">
                    <a-select v-model:value="filters.organizationID" size="large" class="sDash_fullwidth-select"
                              :allowClear="true" mode="multiple">
                      <a-select-option name="organizationID" v-for="(item,index) in userOrganization" :key="index"
                                       :value="item.key">
                        {{ item.value }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="8" :xs="24" class="mb-20">
                  <a-form-item label="Rol" name="roleID" style="margin-bottom: 10px">
                    <a-select v-model:value="filters.roleID" size="large" name="roleID" class="sDash_fullwidth-select"
                              :allowClear="true" mode="multiple">
                      <a-select-option v-for="(item,index) in roleFilters" :key="index" :value="item.key">
                        {{ item.value }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="8" :xs="24" class="mb-20">
                  <a-form-item label="Status" name="status" style="margin-bottom: 10px">
                    <a-select v-model:value="filters.status" size="large" name="roleID" class="sDash_fullwidth-select"
                              :allowClear="true" mode="multiple" @change="handleChange">
                      <a-select-option v-for="(item,index) in UserStatusOptions" :key="index" :value="item.value">
                        {{ item.key }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :xs="24">
                  <a-button size="large" class="btn-signin  mr-2" type="primary" html-type="submit" style="line-height: 1;text-align: center;">
                    <sdFeatherIcons type="filter" size="14" class="mr-1" style="line-height: 1;text-align: center"/>
                    <span>Filter</span>
                  </a-button>
                  <a-button size="large" class="btn-signin" type="secondary" @click.prevent="resetFilters" style="line-height: 1;text-align: center;">
                    <span>Reset</span>
                  </a-button>
                </a-col>
              </a-row>
            </a-form>
          </a-collapse-panel>
        </a-collapse>
      </a-col>
    </a-row>
    <a-row :gutter="15">
      <a-col :md="24">
        <UserListTable/>
      </a-col>
    </a-row>
  </Main>
</template>
<script>
import UserListTable from './component/UserTable';
import {computed, ref, defineComponent, onMounted, watch, reactive} from 'vue';
import {useStore} from 'vuex';
import {Main, CardToolbox, AutoCompleteStyled} from '../styled';
import UserAdd from "./UserAdd";
import locale from "@/static/locale/nl.json";

const {UserStatusOptions} = require("../../utility/enums");

const UserList = defineComponent({
  name: 'UserList',
  components: {Main, CardToolbox, UserListTable, UserAdd, AutoCompleteStyled},
  setup() {
    const {state, dispatch} = useStore();
    const searchData = computed(() => state.headerSearchData);
    const count = computed(() => state.user.count);
    const isLoading = computed(() => state.user.loading);
    const filters = reactive(computed(() => state.user.filters));
    const userOrganization = computed(() => state.filter.userOrganization);
    const selectedRowKeys = ref(0);
    const selectedRows = ref(0);
    const organizationFilters = ref([]);
    const roleFilters = computed(() => state.filter.roleFilters);
    const search = ref(null);
    let debounce = null;
    const activeKey = ref(['0']);
    const expandIconPosition = ref('left');

    const hasFilters = computed(() => {
      return filters.value.organizationID?.length > 0 || filters.value.roleID?.length > 0 || filters.value.status?.length > 0;
    });

    const applyFilters = async () => {
      const searchValues = JSON.parse(JSON.stringify(filters.value));
      searchValues.q = search.value;
      searchValues.status = searchValues.status.length > 0  ? searchValues.status.join(',') : null;
      searchValues.organizationID = searchValues.organizationID.length > 0  ? searchValues.organizationID.join(',') : null;
      searchValues.roleID = searchValues.roleID.length > 0  ? searchValues.roleID.join(',') : null;
      await dispatch('setUserFilters', searchValues);
      await dispatch('getUsers', searchValues);
    };

    const resetFilters = () => {
      filters.value.q = null;
      filters.value.organizationID = [];
      filters.value.roleID = [];
      filters.value.status = [];
      filters.value.currentPage = 1
      dispatch('setUserFilters', filters.value);
      dispatch('getUsers', filters.value);
    };

    watch(search, (query) => {
      clearTimeout(debounce)
      debounce = setTimeout(async () => {
        await dispatch('setUserFilters', {q: query, currentPage: 1});
        await dispatch('getUsers', filters);
      }, 600);
    });

    onMounted(() => {
      dispatch('getUsers', filters.value);
      dispatch('getUserOrganizationFilters');
      dispatch('getRolesFilters');
    });

    return {
      searchData,
      selectedRowKeys,
      selectedRows,
      isLoading,
      count,
      locale,
      filters,
      organizationFilters,
      roleFilters,
      UserStatusOptions,
      applyFilters,
      resetFilters,
      search,
      hasFilters,
      userOrganization,
      activeKey,
      expandIconPosition,
    };
  },
});

export default UserList;
</script>
<style>
.ant-select-dropdown {
  z-index: 99999;
}
.ant-form-vertical.filters .ant-form-item-label {
  margin-left: 10px;
  margin-bottom: -20px;
  z-index: 9999;
}
.ant-form-vertical.filters .ant-form-item-label > label {
  background: #ffffff;
  padding: 0 5px;
}
.ant-collapse {
  border: 0 !important;
}
.ant-collapse > .ant-collapse-item:last-child, .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  min-height: 48px;
  /*height: 48px;*/
  margin-bottom: -1px;
  color: #272B41;
  font-size: 16px;
  background: #ffffff;
  border-bottom: 1px solid #f0f0f0;
  /*border-radius: 10px 10px 0 0;*/
  line-height: 24px;
}
.ant-collapse > .ant-collapse-item .ant-collapse-header .ant-collapse-arrow {
  font-size: 12px !important;
  color: #272B41 !important;
}
.ant-select-selector {
  min-height: 52px;
}
.ant-collapse-extra {
  line-height: 34px;
}
</style>
