<template>
  <sdDrawer
      ref="UserAddRef"
      :form="form"
      :title="locale.page.userCreate.title"
      type="submit"
      :btnText="locale.page.userCreate.btnText"
      :submitBtnText="locale.page.userCreate.submitBtnText"
      @afterVisibleChange="afterVisibleChange"
  >
    <AppWrapper>
      <FormValidationWrap>
        <a-form ref="formRef" :model="form" :rules="rules" layout="vertical" @finish="CreateUser">
          <a-row :gutter="16">
            <a-col :span="9">
              <a-form-item :label="locale.page.userCreate.form.firstName" name="firstName">
                <a-input v-model:value="form.firstName" placeholder="Joe"/>
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item :label="locale.page.userCreate.form.middleName" name="middleName">
                <a-input v-model:value="form.middleName" placeholder=""/>
              </a-form-item>
            </a-col>
            <a-col :span="9">
              <a-form-item :label="locale.page.userCreate.form.lastName" name="lastName">
                <a-input v-model:value="form.lastName" placeholder="Doe"/>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="16">
            <a-col :span="12">
              <a-form-item :label="locale.page.userCreate.form.username" name="username">
                <a-input v-model:value="form.username" placeholder=""/>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item :label="locale.page.userCreate.form.organization" name="organizationID">
                <a-select v-model:value="form.organizationID" size="large" class="sDash_fullwidth-select"
                          :allowClear="true">
                  <a-select-option name="organizationID" v-for="(item,index) in userAllowedPermissions.organizations" :key="index"
                                   :value="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item :label="locale.page.userCreate.form.role" name="roleID">
                <a-select v-model:value="form.roleID" size="large" name="roleID" class="sDash_fullwidth-select"
                          :allowClear="true">
                  <a-select-option v-for="(item,index) in userAllowedPermissions.roles" :key="index" :value="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item :label="locale.page.userCreate.form.password" name="password">
                <a-input v-model:value="form.password" placeholder="" readonly="readonly"
                         style="background-color: #eee"/>
              </a-form-item>
            </a-col>
          </a-row>
          <div
              :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
          >
            <a-button size="large" class="btn-signin" type="primary" html-type="submit" :loading="loadingCreateUser"
                      :disabled="loadingCreateUser"> {{ locale.page.userCreate.form.btnSave }}
            </a-button>
          </div>
        </a-form>
      </FormValidationWrap>
    </AppWrapper>
  </sdDrawer>
</template>
<script>
import {computed, defineComponent, onMounted, reactive, ref} from 'vue';
import {generatePassword} from "../../utility/utility";
import {AppWrapper, FormValidationWrap} from "../styled";
import {useStore} from "vuex";
import locale from "@/static/locale/nl.json";

const UserAdd = defineComponent({
  name: 'UserAdd',
  components: {
    FormValidationWrap,
    AppWrapper,
  },
  setup() {
    const UserAddRef = ref()
    const formRef = ref();
    const {state, dispatch} = useStore();
    const userAllowedPermissions = computed(() => state.user.userAllowedPermissions);
    const loadingCreateUser = computed(() => state.user.loadingCreateUser);
    const form = reactive({
      imageFileUrl: '',
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      password: '',
      organizationID: '',
      roleID: '',
      username: '',
    });
    const rules = {
      firstName: [
        {
          required: true,
          message: locale.validation.required,
        },
      ],
      middleName: [
        {
          required: false,
          message: locale.validation.required,
        },
      ],
      lastName: [
        {
          required: true,
          message: locale.validation.required,
        },
      ],
      username: [
        {
          required: true,
          type: "email",
          message: "Vul een valide e-mailadres in",
        },
      ],
      // email: [
      //   {
      //     required: true,
      //     type: "email",
      //     message: "Vul een valide e-mailadres in",
      //   },
      // ],
      password: [
        {
          required: true,
          message: locale.validation.required,
        },
      ],
      organizationID: [
        {
          required: true,
          message: locale.validation.required,
        },
      ],
      roleID: [
        {
          required: true,
          message: locale.validation.required,
        },
      ],
    };
    const afterVisibleChange = (bool) => {
      if (bool) {
        form.password = generatePassword()
      }
    };
    const closeDrawer = () => {
      UserAddRef.value.onClose();
      formRef.value.resetFields();
      dispatch('getUsers');
    }
    const CreateUser = () => {
      form.email = form.username;
      dispatch('createUser', {value: form, close: closeDrawer});
    };
    onMounted(() => {
      dispatch('getUserAllowedPermissions')
    });

    return {
      form,
      rules,
      afterVisibleChange,
      locale,
      CreateUser,
      loadingCreateUser,
      UserAddRef,
      formRef,
      userAllowedPermissions,
    };
  },
});

export default UserAdd;
</script>
