<template>
  <TableStyleWrapper>
    <TableWrapper class="table-responsive">
      <a-table
          :loading="loadingUsers"
          :dataSource="usersTableData"
          :columns="usersTableColumns"
          :pagination="{
          defaultPageSize: 10,
          total: count,
          current: filters.currentPage,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${count} items`,
        }"
          @change="paginationChange"
      />
    </TableWrapper>
  </TableStyleWrapper>
</template>
<script>
import {TableStyleWrapper} from '../style';
import {TableWrapper} from '../../styled';
import {computed, defineComponent} from 'vue';
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import locale from "@/static/locale/nl.json";
import moment from "moment";
import { useAbility } from '@casl/vue';

const usersTableColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Naam',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: locale.page.userList.columns.organization,
    dataIndex: 'organization',
    key: 'organization',
  },
  {
    title: locale.page.userList.columns.role,
    dataIndex: 'role',
    key: 'role',
  },
  {
    title: locale.page.userList.columns.status,
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: locale.page.userList.columns.createTimestamp,
    dataIndex: 'createTimestamp',
    key: 'createTimestamp',
  },
  {
    title: locale.page.userList.columns.action,
    dataIndex: 'action',
    key: 'action',
    width: '90px',
  },
];
const UserListTable = defineComponent({
  name: 'UserListTable',
  components: {TableStyleWrapper, TableWrapper},
  setup() {
    const {state, dispatch} = useStore();
    const router = useRouter();
    const users = computed(() => state.user.users);
    const loadingUsers = computed(() => state.user.loadingUsers);
    const currentPage = computed(() => state.user.currentPage);
    const filters = computed(() => state.user.filterValues);
    const count = computed(() => state.user.count);
    const pageSize = computed(() => state.user.pageSize);
    const {matched} = useRoute();
    const {path} = matched[1];
    const { can } = useAbility();
    const handleDelete = async (id) => {
      await dispatch('deleteUser', id);
      await dispatch('getUsers', {page: state.user.currentPage, pageSize: state.user.pageSize})
    };
    const handleEdit = (id) => {
      router.push(`${path}/${id}`);
    };
    const resolveStatusVariant = status => {
      if (status === 'ACTIVE') return {variant: 'active', label: 'Actief'}
      if (status === 'PENDING') return {variant: 'pending', label: 'In afwachting'}
      if (status === 'INACTIVE') return {variant: 'inactive', label: 'Inactief'}
      return {variant: '', label: 'Status onbekend'}
    }
    const usersTableData = computed(() =>
        users.value.map((user) => {
          const {id, fullName, email, status, createTimestamp, role, organizationName} = user;
          return {
            key: id,
            id: (<span>{id}</span>),
            name: (
                <div class="user-info">
                  <figcaption>
                    <sdHeading class="user-name" as="h6">
                      {fullName}
                    </sdHeading>
                    <span class="user-designation">{email}</span>
                  </figcaption>
                </div>
            ),
            organization: <span>{organizationName}</span>,
            role: <span>{role}</span>,
            status: <span
                className={`status-text ${resolveStatusVariant(status).variant}`}>{resolveStatusVariant(status).label}</span>,
            createTimestamp: <span>{moment(createTimestamp).format('DD-MM-YYYY')}</span>,
            action: (
                <div class="table-actions">
                  {can('view', 'user') ? (
                  <sdButton onClick={() => handleEdit(id)} class="btn-icon" to="#" type="default" shape="circle">
                    <sdFeatherIcons type="edit" size="16"/>
                  </sdButton>
                  ) : ('')}
                  {can('delete', 'user') ? (
                  <a-popconfirm
                      title="Weet je het zeker? U kunt dit niet meer terugdraaien!"
                      ok-text="Ja"
                      cancel-text="Nee"
                      placement="leftTop"
                      onConfirm={() => handleDelete(id)}
                  >
                    <sdButton class="btn-icon" type="default" to="#" shape="circle">
                      <sdFeatherIcons type="trash-2" size="16"/>
                    </sdButton>
                  </a-popconfirm>
                  ) : ('')}
                </div>
            ),
          };
        }),
    );

    const rowSelection = {
      getCheckboxProps: (record) => ({
        disabled: record.name === 'Disabled user', // Column configuration not to be checked
        name: record.name,
      }),
    };

    const paginationChange = async (event) => {
      await dispatch('setCurrentPageUsers', event.current);
      await dispatch('getUsers');
    }

    return {usersTableColumns, usersTableData, rowSelection, users, loadingUsers, currentPage, count, paginationChange, pageSize, filters};
  },
});

export default UserListTable;
</script>
